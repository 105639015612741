<template>
  <div class="row justify-center bg-grey-3">
    <q-dialog v-model="confirm" persistent>
      <q-card style="min-width:250px">
        <q-card-section class="q-gutter-md">
          <q-select
            outlined
            stack-label
            v-model="selKelas"
            label="Kelas"
            :options="kelas"
            @input="getMapelInKelas"
          />
          <q-select
            outlined
            stack-label
            v-model="selMapel"
            label="Mata Pelajaran"
            :disable="selKelas == null"
            :options="mapel"
          />
          <q-input outlined label="Tanggal" v-model="date" readonly>
            <template v-slot:prepend>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    :options="dateOptionFn"
                    v-model="date"
                    mask="YYYY-MM-DD"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn @click="$router.go(-1)" flat label="Kembali" color="primary" />
          <q-btn
            filled
            label="Mulai"
            color="primary"
            :disable="selKelas == null || selMapel == null"
            @click="cekAbsensi()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-sm">
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 214px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>No.</th>
            <th>Nama</th>
            <th style="max-width:100px">Status</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ i + 1 }}</td>
            <td class="text-uppercase">{{ val.nama }}</td>
            <td style="min-width:100px">
              <q-select
                dense
                outlined
                v-model="val.status"
                :options="opsiStatus"
              />
            </td>
          </tr>
        </tbody>
      </q-markup-table>
      <q-card-actions class="q-pa-none">
        <q-btn
          filled
          color="negative"
          class="full-width no-border-radius"
          @click="guard = true"
          >simpan</q-btn
        >
      </q-card-actions>
    </q-card>
    <q-dialog v-model="guard" persistent>
      <q-card style="width:250px">
        <q-card-section class="row items-center">
          <span class="q-ml-sm"
            >Apa anda yakin ingin menyimpan daftar kehadiran?</span
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Tidak" color="primary" v-close-popup />
          <q-btn
            flat
            label="Ya"
            color="primary"
            @click="tambahKehadiran()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
import General from "@/mixins/General.js";
import { mapGetters } from "vuex";

export default {
  mixins: [General],
  data() {
    return {
      date: null,
      user: {},

      guard: false,
      confirm: true,
      opsiStatus: ["Hadir", "Izin", "Sakit", "Alfa"],
      selKelas: null,
      selMapel: null,
      kelas: [],
      mapel: [],
      siswa: [],
      searchTerm: "",
      libur: [],
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.getListKelas();
    await this.getLibur();
    this.date = moment().format("YYYY-MM-DD");
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getLibur() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/absensi/getlibur/${localStorage.getItem("id_tahun_ajaran")}`
      );
      this.libur = resp.data;
      this.$q.loading.hide();
    },
    async getMapelInKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/input_nilai/getmapelinkelas/${this.selKelas.value}/${this.user.id}/${this.user.is_super_user}`
      );
      this.mapel = resp.data;
      this.$q.loading.hide();
    },
    async cekAbsensi() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let url = `/penilaian/absensi/cekkelas/${this.date}/${this.selKelas.value}/${this.selMapel.value}`;
      let resp = await this.$http.get(url);
      let is_data_exist = resp.data.length > 0;

      let libur = this.libur.filter((val) => val.tanggal == this.date);
      let is_libur = libur.length > 0;
      if (is_data_exist) {
        let msg = `Data absen untuk kelas ${this.selKelas.label} pada tanggal yang dipilih sudah ada`;
        this.notifyNegative(msg);

        this.selKelas = null;
        this.selMapel = null;
        this.date = moment().format("YYYY-MM-DD");
        this.confirm = true;
      } else if (is_libur) {
        let msg = `Tanggal ${moment(this.date).format(
          "DD MMM YYYY"
        )} Adalah hari libur`;
        this.notifyNegative(msg);

        this.selKelas = null;
        this.selMapel = null;
        this.date = moment().format("YYYY-MM-DD");
        this.confirm = true;
      } else {
        this.getSiswaKelas();
        this.confirm = false;
      }
      this.$q.loading.hide();
    },
    async getListKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let url = `/penilaian/absensi/getkelas/${localStorage.getItem(
        "jenjang"
      )}/${this.user.id}/${this.user.is_super_user}`;
      let resp = await this.$http.get(url);
      this.kelas = resp.data;
      this.$q.loading.hide();
    },
    async getSiswaKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let url = `/penilaian/absensi/getsiswa/${this.selKelas.value}`;
      let resp = await this.$http.get(url);
      for (let i in resp.data) {
        resp.data[i].status = "Hadir";
        resp.data[i].waktu = this.date;
      }
      if (resp.data.length == 0) {
        confirm = true;
        let msg = `Tidak Ada Siswa di Kelas ${this.selKelas.label}`;
        this.notifyNegative(msg);
      } else {
        this.siswa = resp.data;
      }
      this.$q.loading.hide();
    },
    async tambahKehadiran() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      if (!this.guard) {
        this.guard = true;
      } else {
        let bundle = {
          mapel: this.selMapel,
          rombel: this.selKelas,
          id_tahun_ajaran: localStorage.getItem("id_tahun_ajaran"),
          siswa: this.siswa,
        };
        await this.$http
          .post(`/penilaian/absensi/tambah`, bundle)
          .then((resp) => {
            this.$q.notify({
              message: "Absensi Berhasil Disimpan!",
              color: "positive",
            });
            this.$router.push("/");
          });
      }
      this.$q.loading.hide();
    },
    dateOptionFn(date) {
      return date <= moment().format("YYYY/MM/DD");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
